import React from "react";
import { Categories } from "./Loans/Categories";

export const Loans = () => {
  return (
    <React.Fragment>
      <Categories />
    </React.Fragment>
  );
};
